import _ from "lodash";
import { useEffect, useState } from "react";
import Select, { OptionsType, Props as SelectProps } from "react-select";
import styled from "styled-components";

export const Label = styled.label`
  ${({ theme }) => `
    z-index: 4;
    display: table;
    margin-bottom: -${theme.space[1]}px;
    margin-left: ${theme.space[3]}px;
    padding-left: ${theme.space[1]}px;
    position: relative;
    color: rgba(19, 19, 21, 0.65);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 15px;
    background-color: ${theme.palette.neutral.white};
  `};
`;

interface IHintsSelect extends SelectProps {
  label: string;
  defaultOptions?: OptionsType<{ label: string; value: string }>;
  loadOptions?: (
    value?: string
  ) => Promise<OptionsType<{ label: string; value: string }>>;
}

export const HintsSelect: React.FC<IHintsSelect> = ({
  label,
  defaultOptions,
  loadOptions,
  ...props
}) => {
  const [options, setOptions] = useState<
    OptionsType<{ label: string; value: string }>
  >(defaultOptions ?? []);

  const handleLoadOptions = _.debounce((value?: string) => {
    loadOptions && loadOptions(value).then(setOptions);
  }, 1000);

  const onInputChange = (value: string) => {
    if (value.length && loadOptions) {
      handleLoadOptions(value);
    }
  };

  useEffect(() => {
    handleLoadOptions(undefined);
  }, []);

  return (
    <div>
      <Label>{label}</Label>
      <Select
        isClearable
        {...props}
        options={options}
        onInputChange={onInputChange}
      />
    </div>
  );
};
