import { Box } from "components/Box";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Warring } from "components/Warring";
import { FieldSetTitle } from "components/FieldSetTitle";
import { Flex } from "components/Flex";
import { Hr } from "components/Hr";
import { Radio } from "components/Radio";
import { RadioGroup } from "components/RadioGroup";
import { Select } from "components/Select";
import { Textarea } from "components/Textarea";
import { useFetchAPI } from "hooks/fetchAPI";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  DICTIONARY_PARTNERS_URL,
  DISRUPTION_CATEGORIES_URL,
  DISRUPTION_STATUSES_URL,
} from "constants/path";
import {
  DisruptionCategoriesType,
  DisruptionStatusesType,
  IDisruption,
} from "types/disruption";
import { useHistory } from "react-router-dom";
import {
  addDisruptionFlag,
  fetchDisruptionFlag,
  fetchDisruptions,
  postDisruption,
  removeDisruption,
  removeDisruptionFlag,
  updateDisruption,
} from "services/disruptions";
import { useFetchDictionaryUsers } from "hooks/fetchDictionaryUsers";
import { addDays, subDays, format } from "date-fns";

import DatePicker from "react-datepicker";

import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import { FormError } from "types/error";
import { dictionaryCategories } from "constants/dictionaryCategories";
import { HintsSelect } from "components/HintsSelect";
import { getDisruptionDaysLength } from "utilities/getDisruptionDaysLength";
import { DisruptionIz } from "./DisruptionIz";
import { useParams } from "react-router-dom";
import { DisruptionTags } from "./DisruptionTags";
import Flag from "components/Flag";
import {
  useTableSidebarContext,
  useTableSidebarDispatchContext,
} from "contexts/TableSidebarContext";
import { FORMAT_DATE_BE, formatDate } from "../utilities/formatDate";
import { useFetchLoggedUserRoles } from "hooks/fetchLoggedUserRoles";

export interface IDisruptionForm {
  disruption?: IDisruption;
  getDisruption?: () => void;
}

interface IDisruptionFormData {
  code: string;
  name: string;
  description: string;
  continuation: string;
  startDocument: string;
  endDocument: string;
  submissionDate: string;
  endDocumentSubmissionDate: string;
  endDocumentSubmissionDateFrom: string;
  endDocumentSubmissionDateTo: string;
  submittingEntity: string;
  affectsEntities: boolean;
  affectedEntities: Array<{
    name: string;
    scope: string;
  }>;
  startDate: string;
  endDate: string;
  category: string;
  status: string;
  izs: Array<string>;
  tags: Array<{ id: string; name: string }>;
  assignedUser: string;
  isFlag?: boolean;
}

interface IPartner {
  id: string;
  name: string;
}

interface IGrid {
  columns: number;
}

interface IStatus {
  description: string;
  final: boolean;
  id: string;
  name: string;
}

const Grid = styled.div<IGrid>`
  ${({ theme, columns }) => `
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const AffectedEntitiesGrid = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-column-gap: ${theme.space[4]}px;
    grid-row-gap: ${theme.space[3]}px;
  `};
`;

const StyledTextarea = styled(Textarea)`
  height: 30px;
  width: 100%;
  margin-bottom: -3px;
`;
const StyledBox = styled(Box)`
  width: 100%;
`;
const StyleSelect = styled(Select)`
  height: 38px;
`;

const openStatusId = "069287f6-7af2-4ff7-b061-878c4dad4fcb";

export const DisruptionForm: React.FC<IDisruptionForm> = ({
  getDisruption,
  disruption,
}) => {
  const history = useHistory();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<IStatus>(null);
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [tooltipStatusMessage, setTooltipStatusMessage] = useState<
    string | null
  >(null);
  const { tableData } = useTableSidebarContext();
  const { setTableData } = useTableSidebarDispatchContext();
  const { disruptionId } = useParams<{ disruptionId: string }>();

  const { register, handleSubmit, control, watch, reset, setValue } =
    useForm<IDisruptionFormData>({
      defaultValues: {
        code: "",
        name: "",
        description: "",
        continuation: "",
        startDocument: "",
        endDocument: "",
        submissionDate: "",
        endDocumentSubmissionDate: "",
        endDocumentSubmissionDateTo: "",
        endDocumentSubmissionDateFrom: "",
        submittingEntity: "",
        affectsEntities: false,
        affectedEntities: [
          {
            name: "",
            scope: "",
          },
        ],
        startDate: "",
        endDate: "",
        category: "",
        status: "",
        izs: [],
        tags: [],
        assignedUser: "",
        isFlag: false,
      },
    });

  const {
    fields: affectedEntitiesFields,
    remove: removeAffectedEntity,
    append: appendAffectedEntity,
  } = useFieldArray<IDisruptionFormData>({
    control,
    name: "affectedEntities",
    shouldUnregister: true,
  });

  const { dictionaryUsers } = useFetchDictionaryUsers();

  const { data: disruptionCategories } = useFetchAPI<DisruptionCategoriesType>(
    DISRUPTION_CATEGORIES_URL
  );

  const { data: disruptionStatuses } = useFetchAPI<DisruptionStatusesType>(
    DISRUPTION_STATUSES_URL
  );
  const { data: partners } = useFetchAPI<IPartner[]>(DICTIONARY_PARTNERS_URL);

  const { loggedUserRoles } = useFetchLoggedUserRoles();

  const watcher = watch();

  const { category, status, affectedEntities } = watcher;

  useEffect(() => {
    if (disruption) {
      fetchDisruptionFlag(disruption.id)
        .then(() => setValue("isFlag", true))
        .catch(() => setValue("isFlag", false));
    }
  }, [disruption]);

  useEffect(() => {
    const option = disruptionCategories?.find((el) => el.id === category);
    option
      ? setTooltipMessage(dictionaryCategories[option.name])
      : setTooltipMessage(null);
  }, [category]);

  useEffect(() => {
    // if (status === openStatusId) {
    const option = disruptionStatuses?.find((el) => el.id === status);
    if (option) {
      setTooltipStatusMessage(option.description);
    } else {
      setTooltipStatusMessage(null);
    }
    // }
  }, [status]);

  const onSubmit = handleSubmit((data) => {
    const parsedFormData = {
      ...data,
      continuationData: ["", undefined].includes(data.continuation)
        ? null
        : { id: data.continuation, name: "" },
      startDate:
        data.startDate === ""
          ? null
          : formatDate(new Date(data.startDate), "yyyy-MM-dd"),
      endDocumentSubmissionDate:
        data.endDocumentSubmissionDate === ""
          ? null
          : formatDate(new Date(data.endDocumentSubmissionDate), "yyyy-MM-dd"),
      affectedEntities: data.affectsEntities ? affectedEntities : [],
      izs: data.izs && data.izs.map((iz) => ({ id: iz, name: "" })),
      tags:
        data.tags &&
        data.tags.map((tag) => ({ id: tag.id, name: tag.name, active: true })),
    };

    if (disruption) {
      updateDisruption({
        disruption: parsedFormData,
        disruptionId: disruption.id,
      })
        .then(() => {
          if (getDisruption) getDisruption();
          toast.success("Zakłócenie zostało zaktualizowane.");
        })
        .catch(() => {
          toast.error("Nie udało się zaktualizować zakłócenia.");
        });
    } else {
      delete parsedFormData.isFlag;
      postDisruption({
        disruption: parsedFormData,
      })
        .then((response) => {
          if (response.id) {
            history.push(`/objects/disruptions/${response.id}`);
            toast.success("Zakłócenie zostało dodane.");
          } else {
            toast.error("Proszę uzupełnić pola.");
          }
        })
        .catch((error: FormError) => {
          toast.error(
            <>
              {error.map(({ field, message }) => (
                <div key={field}>
                  <strong>{field}</strong>: {message}
                </div>
              ))}
            </>
          );
        });
    }
  });

  const onRemoveDisruptionClick = (disruptionId: string) => {
    if (confirm("Czy na pewno chcesz usunąć zakłócenie?")) {
      removeDisruption({ disruptionId })
        .then(() => {
          toast.success("Zakłócenie zostało usunięte.");
          history.push("/objects/disruptions");
        })
        .catch(() => {
          toast.error("Nie udało się usunąć zakłócenia.");
        });
    }
  };

  const onFlagClick = () => {
    if (disruption) {
      watcher.isFlag &&
        removeDisruptionFlag(disruption.id).then(() =>
          setValue("isFlag", false)
        );
      !watcher.isFlag &&
        addDisruptionFlag(disruption.id).then(() => setValue("isFlag", true));
    }
  };

  useEffect(() => {
    if (disruption && disruptionCategories && disruptionStatuses && partners) {
      reset({
        ...disruption,
        continuation:
          disruption.continuationData === null ||
          disruption.continuationData === undefined
            ? ""
            : disruption.continuationData.id,
        endDocumentSubmissionDate:
          disruption.endDocumentSubmissionDate === null
            ? ""
            : disruption.endDocumentSubmissionDate,
        izs: disruption.izs ? disruption.izs.map(({ id }) => id) : [],
        tags: disruption.tags
          ? disruption.tags.map((tag) => ({
              id: tag.id,
              name: tag.name,
              active: true,
            }))
          : [],
      });
    }
    setTimeout(() => {
      setTableData({ ...tableData, detailsObject: false });
    });
  }, [
    disruption,
    disruptionCategories,
    disruptionStatuses,
    partners,
    dictionaryUsers,
  ]);

  useEffect(() => {
    if (status)
      setSelectedStatus(disruptionStatuses.find((item) => item.id === status));
  }, [disruptionStatuses, status]);

  const PageHeaderButtonsGroupPortal = document.getElementById(
    "page-header-buttons-group-portal"
  );

  const renderDateSubmision = () => {
    // guid'y to id statusów z opcji "Cofnięte", "Ostateczne" oraz "Wycofane".
    if (selectedStatus?.final) {
      return (
        <>
          <Input label="Nr pisma" {...register("endDocument")} />
          <Controller
            control={control}
            name="endDocumentSubmissionDate"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                onChange={(date) => {
                  field.onChange(date instanceof Date ? date : "");
                }}
                customInput={<Input label="Data pisma" value={field.value} />}
                dateFormat="dd-MM-yyyy"
                portalId="root-datepicker-portal"
              />
            )}
          />
        </>
      );
    }
  };

  return (
    <>
      {disruption &&
        disruption.problems &&
        disruption.problems.map((item, index) => (
          <div key={index}>
            <Warring
              name={item.name}
              severity={item.severity}
              type={item.type}
            />
          </div>
        ))}
      <form onSubmit={onSubmit}>
        <Flex alignItems="center" justifyContent="space-between" mb={5}>
          <FieldSetTitle>Dane ogólne</FieldSetTitle>
          <Flex alignItems="center" justifyContent="space-between">
            {disruption ? (
              <>
                <Button type="button" bordered onClick={onFlagClick}>
                  {watcher?.isFlag ? "Nie obserwuj" : "Obserwuj"}
                </Button>
                <Flag active={!!watcher?.isFlag} />
              </>
            ) : (
              <Button type="submit" bordered>
                Zapisz
              </Button>
            )}
          </Flex>
        </Flex>
        <Grid columns={4}>
          <StyleSelect label="Zgłaszający" {...register("submittingEntity")}>
            <option value="" />
            {partners !== null
              ? partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.name}
                  </option>
                ))
              : null}
          </StyleSelect>

          <StyleSelect label="Przypisany" {...register("assignedUser")}>
            <option value="" />
            {dictionaryUsers !== null
              ? dictionaryUsers.map((item) => (
                  <option key={item.email} value={item.email}>
                    {item.email}
                  </option>
                ))
              : null}
          </StyleSelect>

          <StyleSelect
            label="Kategoryzacja"
            {...register("category")}
            tooltipMessage={tooltipMessage}
            withTooltip
          >
            <option value="" />
            {disruptionCategories?.map((category) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </StyleSelect>

          <Controller
            control={control}
            name="continuation"
            render={({ field: { onChange } }) => (
              <HintsSelect
                label="Kontynuacja"
                onChange={(disruption) => onChange(disruption?.value)}
                defaultValue={
                  disruption?.continuationData
                    ? {
                        label: disruption.continuationData.name,
                        value: disruption.continuationData.id,
                      }
                    : null
                }
                loadOptions={(value?: string) =>
                  fetchDisruptions({
                    number: value,
                    sortOrder: "asc",
                    sortBy: "number",
                  }).then((response) =>
                    response.data.map((disruption) => ({
                      label: disruption.number,
                      value: disruption.id,
                    }))
                  )
                }
              />
            )}
          />
        </Grid>
        <Box mt={3}>
          <Textarea label="Opis" {...register("description")} />
        </Box>
        <Hr />
        <FieldSetTitle>Daty</FieldSetTitle>
        <Grid columns={3}>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                maxDate={subDays(
                  new Date(
                    `${
                      endDate
                        ? endDate
                        : control.defaultValuesRef.current.endDate
                    }`
                  ),
                  1
                )}
                onChange={(date) => {
                  setStartDate(new Date(`${date}`));
                  field.onChange(date instanceof Date ? date : "");
                }}
                customInput={
                  <Input label="Dzień rozpoczęcia" value={field.value} />
                }
                dateFormat="dd-MM-yyyy"
                portalId="root-datepicker-portal"
              />
            )}
          />
          <Input
            label="Nr pisma - pow. o roszczeniu"
            {...register("startDocument")}
          />
          <Controller
            control={control}
            name="submissionDate"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                onChange={(date) => {
                  field.onChange(
                    date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                  );
                }}
                customInput={
                  <Input label="Pow. o roszczeniu" value={field.value} />
                }
                dateFormat="dd-MM-yyyy"
                portalId="root-datepicker-portal"
              />
            )}
          />
          <Select
            label="Status"
            {...register("status")}
            tooltipMessage={tooltipStatusMessage}
            withTooltip
          >
            <option value="" />
            {disruptionStatuses?.map((status) => (
              <option value={status.id} key={status.id}>
                {status.name}
              </option>
            ))}
          </Select>

          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                minDate={addDays(
                  new Date(
                    `${
                      startDate
                        ? startDate
                        : control.defaultValuesRef.current.startDate
                    }`
                  ),
                  0
                )}
                onChange={(date) => {
                  field.onChange(
                    date instanceof Date ? format(date, "yyyy-MM-dd") : ""
                  );
                  setEndDate(date ? new Date(`${date}`) : null);
                }}
                disabled={status === openStatusId}
                customInput={
                  <Input label="Dzień zakończenia" value={field.value} />
                }
                dateFormat="dd-MM-yyyy"
                portalId="root-datepicker-portal"
              />
            )}
          />
          {renderDateSubmision()}
          {disruption ? (
            <>
              <Input
                label="Ilość dni kalendarzowych"
                disabled
                value={getDisruptionDaysLength({
                  startDate: watcher.startDate,
                  endDate: watcher.endDate !== "" ? watcher.endDate : null,
                })}
              />
              <Input
                label="Ilość dni roboczych"
                disabled
                value={getDisruptionDaysLength({
                  startDate: watcher.startDate,
                  endDate: watcher.endDate !== "" ? watcher.endDate : null,
                  business: true,
                })}
              />
            </>
          ) : null}
        </Grid>
        <Hr />
        <Controller
          name="izs"
          control={control}
          render={({ field: { value } }) => (
            <DisruptionIz
              disruption={disruption}
              value={value}
              onChange={(values) =>
                setValue(
                  "izs",
                  values.map((value) => value.value)
                )
              }
            />
          )}
        />
        <Hr />
        {
          <Controller
            control={control}
            name="tags"
            render={({ field: { onChange } }) => (
              <DisruptionTags
                selectedOptions={watcher.tags}
                onChange={(values) => {
                  onChange(
                    values.map((value) => ({
                      id: value.value,
                      name: value.label,
                      active: true,
                    }))
                  );
                }}
              />
            )}
          />
        }
        <Hr />
        <FieldSetTitle>
          <Flex alignItems="center">
            Możliwy wpływ na innego partnera
            <Flex ml={8} alignItems="center" flex={1}>
              <Controller
                render={({ field: { onChange, name } }) => (
                  <RadioGroup>
                    <Radio
                      label="Tak"
                      name={name}
                      onChange={() => {
                        if (watcher.affectedEntities.length === 0) {
                          appendAffectedEntity({ name: "", scope: "" });
                        }
                        onChange(true);
                      }}
                      checked={watcher.affectsEntities}
                    />
                    <Radio
                      label="Nie"
                      name={name}
                      onChange={() => onChange(false)}
                      checked={!watcher.affectsEntities}
                    />
                  </RadioGroup>
                )}
                name="affectsEntities"
                control={control}
              />
              <Box ml="auto">
                <Button
                  type="button"
                  onClick={() => appendAffectedEntity({ name: "", scope: "" })}
                >
                  Dodaj element
                </Button>
              </Box>
            </Flex>
          </Flex>
        </FieldSetTitle>
        {watcher.affectsEntities ? (
          <AffectedEntitiesGrid>
            {affectedEntitiesFields.map((field, index) => (
              <Flex
                key={field.id}
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Select
                  label="Partner"
                  {...register(`affectedEntities.${index}.name` as const)}
                  defaultValue={field.name}
                >
                  <option value="" />
                  {partners
                    ? partners.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </Select>
                <StyledBox mx={2}>
                  <StyledTextarea
                    label="Zakres"
                    {...register(`affectedEntities.${index}.scope` as const)}
                    defaultValue={(field as any).scope}
                  />
                </StyledBox>
                <Button
                  type="button"
                  bordered
                  onClick={() => {
                    removeAffectedEntity(index);
                  }}
                >
                  Usuń
                </Button>
              </Flex>
            ))}
          </AffectedEntitiesGrid>
        ) : null}
        {PageHeaderButtonsGroupPortal
          ? createPortal(
              <>
                {disruption &&
                  loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_OVERALL_DELETE"
                  ) && (
                    <Button
                      onClick={() => onRemoveDisruptionClick(disruption.id)}
                    >
                      Usuń zakłócenie
                    </Button>
                  )}
                {disruption ? (
                  loggedUserRoles?.includes(
                    "ROLE_DISRUPTIONS_LIST_OVERALL_UPDATE"
                  ) && (
                    <Button onClick={onSubmit} bordered>
                      Zapisz
                    </Button>
                  )
                ) : (
                  <Button onClick={onSubmit} bordered>
                    Dodaj
                  </Button>
                )}
              </>,
              PageHeaderButtonsGroupPortal
            )
          : null}
      </form>
    </>
  );
};
